// paper & background
$paper: #ffffff;

// primary
$primaryLight: #e3f2fd;
$primaryMain: #2196f3;
$primaryDark: #1e88e5;
$primary200: #90caf9;
$primary800: #1565c0;

// secondary
$secondaryLight: #ede7f6;
$secondaryMain: #673ab7;
$secondaryDark: #5e35b1;
$secondary200: #b39ddb;
$secondary800: #4527a0;

// yellow
$yellowLight: #fff8e1;
$yellowMain: #ffe57f;
$yellowDark: #ffc107;

// success
$successLight: #d8ffe3;
$success200: #69f0ae;
$successMain: #07a94a;
$successDark: #007e2f;

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;
}